'use strict';

let HD = HD || {};
let UTILS = UTILS || {};
var productColorNumber;

HD.metaTags = {};
HD.utils = UTILS || {};
HD.scrollTopPosition = $(window).scrollTop();

HD.utils.mobileMenuOpen = function() {
    let menu = $('.js-mob-menu');
    let hamburgerMenu = $('.js-hamburger');
    let menuItem = $('.l-navbar__menu-item');

    function mobileMenuVisibility() {
        hamburgerMenu.toggleClass('hamburger--open');
        menu.toggleClass('l-navbar__mob--hide');
    }

    hamburgerMenu.on( 'click', function() {
        mobileMenuVisibility();
    });

    menuItem.on('click', function() {
        setTimeout(function(){
            mobileMenuVisibility();
        }, 100);
    });
};

HD.utils.changeBillingAddressOption = function(isChecked) {

    var billingAddressLayer = $('.js-billing-address'),
        billingAddresWrapper = $('#billing-address-wrapper');

    if (isChecked) {
        billingAddressLayer.fadeOut();
        billingAddresWrapper.fadeIn();

        $(billingAddressLayer).each(function() {
            $(this).find(':input:not(.btn)').removeAttr('required');
        });

    } else {
        billingAddressLayer.fadeIn();
        billingAddresWrapper.fadeOut();

        $(billingAddressLayer).each(function() {
            $(this).find(':input:not(.btn)').attr('required');
        });
    }
};

HD.utils.closePopup = function(element) {
    let wrapper = element.parentNode.parentNode;

    element.parentNode.parentNode.classList.remove('popup-wrapper--show');

    setTimeout(function() {
        wrapper.remove();
    }, 800);
}

HD.utils.copyToClipboard = function(element) {
    const text = element.previousElementSibling.innerHTML;
    navigator.clipboard.writeText(text)
    document.querySelector('.js-copy-replace').innerHTML = 'Copied!';
}

HD.utils.changeVisibility = function(wrapperId, selectedLayerId) {
    console.log(wrapperId, selectedLayerId);

    $(`#${wrapperId} [data-visiblity-layer]`).attr('hidden', true);
    $(`#${wrapperId} [data-visiblity-layer="${selectedLayerId}"]`).attr('hidden', false);
};

HD.refinement = {
    groupRevealed: null,
    getCurrentCategorySlug: function() {
        return $('[data-filter-category]').attr('data-filter-category');
    },
    getCategoryName: function(slug) {
        var name = $('[data-param="' + slug + '"]').html();

        if (name) {
            name = name.trim();
        }

        return name;
    },
    getLabelName: function(slug) {
        var name = $('input[value="' + slug + '"]').parent().find('.js-label-name').html();

        if (name) {
            name = name.trim();
        }

        return name;
    },
    getCurrentLabelSlug: function() {
        return $('input[name="labelSlug"]:checked').val();
    },

    filterBy: function(type, value, withoutTopicRefinement) {
        var categorySlug = HD.refinement.getCurrentCategorySlug();
        var labelSlug = HD.refinement.getCurrentLabelSlug();
        var url;

        if (type === 'category') {
            if (withoutTopicRefinement) {
                url = value;
            } else {
                url = value + (labelSlug ? '/' + labelSlug : '');
            }

        } else if (type === 'label') {
            url = categorySlug + '/' + value;

        } else {
            url = categorySlug;
        }

        history.pushState({url}, null, url);
        HD.pushStateCounter++;
        HD.refinement.requestContent(url);
        trackPageGTM();
    },
    findParams: function(value) {

        if (value.indexOf('?') >= 0) {
            value += '&';
        } else {
            value += '?';
        }

        return value + Math.random();
    },
    requestContent: function(url) {
        $('.loading').fadeIn();

        if (HD.infiniteElement) {
            HD.infiniteElement.destroy();
        }

        if ( url.slice(-1) === '/' ) {
            url = url.slice(0, -1);
        }

        url += (HD.refinement.groupRevealed ? '?groupRevealed=' + HD.refinement.groupRevealed : '');
        var newUrl = HD.refinement.findParams(url);

        setTimeout(function() {
            $.ajax({
                url: newUrl,
                dataType: 'html',
                success: function(responseText) {

                    $('#js-content').replaceWith(responseText);

                    var codes = $('#js-content').find("script");

                    for (var i=0; i<codes.length; i++) {
                        eval(codes[i].text);
                    }

                    document.title = HD.metaTags.title;
                    $('meta[name=description]').attr('content', HD.metaTags.description);

                    $('html, body').animate({ scrollTop: 0 }, 'slow');

                    Waypoint.destroyAll();

                    setTimeout(function() {
                        Waypoint.refreshAll();
                        runUniversalScripts();
                    }, 200);

                    setTimeout(function() {
                        $('.loading').fadeOut();
                    }, 200);
                },

                error: function(responseText) {
                    setTimeout(function() {
                        $('.loading').fadeOut();
                    }, 200);
                }
            });
        }, 300);

        return false;
    },
    initPopstate: function() {
        window.addEventListener('popstate', function(e) {
            var character = e.state;

            if (character === null && HD.pushStateCounter) {
                location.reload();
            } else if (character && character.url) {
                HD.refinement.requestContent(character.url);
            }
        });
    },
    init: function() {
    }
};

HD.form = {

    inputLabel(inputType){
		$(inputType).each(function(){
            var $this = $(this);

            if ($this.val().length) {
                $this.parent('.form__group').find('.form__label').addClass("form__label--active");
            }

			$this.focus(function(){
                $this.parent('.form__group').find('.form__label').addClass("form__label--active");
			});

			$this.blur(function(){
				if ($this.val() === '' || $this.val() === 'blank'){
                    $this.parent('.form__group').find('.form__label').removeClass("form__label--active");
				}
			});
		});
    },

    selectIconUp(select) {
        $(select).on('click', function(){
            var $this = $(this);

            $this.find('svg').toggleClass("form__select-icon--active");

            $(document).mouseup(function (e){
                if (!$this.is(e.target) && $this.has(e.target).length === 0 && $this.find('svg').hasClass("form__select-icon--active")){
                    $this.find('svg').removeClass("form__select-icon--active");
                }
            });
        });
    },

    cardNumberValidation: function() {
        const cardNumberElement = document.querySelector('#card-number');

        if (cardNumberElement) {
            cardNumberElement.addEventListener("input", (event) => {
                event.preventDefault();
                let target = event.target, position = target.selectionEnd, length = target.value.length;
                target.value = target.value.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();
                target.selectionEnd = position += ((target.value.charAt(position - 1) === ' ' && target.value.charAt(length - 1) === ' ' && length !== target.value.length) ? 1 : 0);

                target.value = target.value.substr(0, 19);
            });
        }
    },

    checkParams: function() {
        let isColorSelected = $('input[name=colour_id]:checked').val();
        let isHingeHolesRequired = $('input[name=hinge_holes]:checked');
        let isHingeHolesRequiredVal = isHingeHolesRequired.val();
        let extraHingeHolesBtn = $('#extra-hinge-holes-btn');

        function Param(id) {
            let element = $('#' + id);
            this.value = element.val() || null;
            this.isDisabled = element.attr('disabled') ? true : false;
        };

        let paramElements = [
            {name: 'doorHeight', id: 'door_height'},
            {name: 'doorWidth', id: 'door_width'},
            {name: 'drawerHeight', id: 'drawer_height'},
            {name: 'drawerWidth', id: 'drawer_width'},
            {name: 'doorHeightCustom', id: 'custom_height_0'},
            {name: 'doorWidthCustom', id: 'custom_width_0'},
            {name: 'drawerHeightCustom', id: 'custom_height_1'},
            {name: 'drawerWidthCustom', id: 'custom_width_1'},
            {name: 'selectedHinge', id: 'selected_hanging'},
            {name: 'hingeHolesTop', id: 'hinge_holes_from_top'},
            {name: 'hingeHolesBottom', id: 'hinge_holes_from_bottom'},
            {name: 'extraHingeHolesTop', id: 'extra_hinge_holes_from_top'},//id: 'extra_hinge_holes_top'
            {name: 'extraHingeHolesBottom', id: 'extra_hinge_holes_from_bottom'},//id: 'extra_hinge_holes_bottom'
        ];

        let params = {};

        paramElements.forEach((paramElement) =>
            params[ paramElement.name ] = new Param( paramElement.id )
        );



        if( isColorSelected != undefined ) {
            if( params.doorHeight.value != null && params.doorHeight.isDisabled === false && params.doorWidth.value != null  && params.doorWidth.isDisabled === false) {
                $('.js-submit-btn').removeAttr('disabled');

                hingeHolesRequired();
                extraHingeHoles();

            } else if ( params.doorHeightCustom.value != null && params.doorHeightCustom.isDisabled === false && params.doorWidthCustom.value != null && params.doorWidthCustom.isDisabled === false ) {
                $('.js-submit-btn').removeAttr('disabled');

                hingeHolesRequired();
                extraHingeHoles();

            } else if ( params.drawerHeight.value != null && params.drawerHeight.isDisabled === false && params.drawerWidth.value != null  && params.drawerWidth.isDisabled  === false) {
                $('.js-submit-btn').removeAttr('disabled');

                hingeHolesRequired();
                extraHingeHoles();

            } else if ( params.drawerHeightCustom.value != null && params.drawerHeightCustom.isDisabled === false && params.drawerWidthCustom.value != null && params.drawerWidthCustom.isDisabled === false) {
                $('.js-submit-btn').removeAttr('disabled');

                hingeHolesRequired();
                extraHingeHoles();

            } else {
                $('.js-submit-btn').attr('disabled', 'disabled');
            }
        };

        function hingeHolesRequired() {

            if (isHingeHolesRequiredVal === 'yes') {
                $('.js-submit-btn').attr('disabled', 'disabled');

                if( params.selectedHinge.value != null && params.selectedHinge.isDisabled === false && params.hingeHolesTop.value != null && params.hingeHolesTop.isDisabled === false && params.hingeHolesBottom.value != null  && params.hingeHolesBottom.isDisabled === false ) {
                    $('.js-submit-btn').removeAttr('disabled');
                }

            } else {
                $('.js-submit-btn').removeAttr('disabled');
            }
        };

        function extraHingeHoles() {

            if(extraHingeHolesBtn.hasClass('js-active')) {
                $('.js-submit-btn').attr('disabled', 'disabled');

                if ( ( params.extraHingeHolesTop.value != null && params.extraHingeHolesTop.isDisabled === false) || ( params.extraHingeHolesBottom.value != null && params.extraHingeHolesBottom.isDisabled === false) ) {
                    $('.js-submit-btn').removeAttr('disabled');
                }
            }
        };
    },

    checkParamsForAccessories: function() {
        let isColors = $('input[name=colour_id]').length;
        let isColorSelected = $('input[name=colour_id]:checked').val();

        function Param(id) {
            let element = $('#' + id);
            this.isElement = element.length;
            this.value = element.val() || null;
            this.isDisabled = element.attr('disabled') ? true : false;
        };

        let paramElements = [
            {name: 'accessoryHeight', id: 'accessory_height'},
            {name: 'accessoryWidth', id: 'accessory_width'},
            {name: 'accessoryLength', id: 'accessory_length'},
        ];

        let params = {};

        paramElements.forEach((paramElement) =>
            params[ paramElement.name ] = new Param( paramElement.id )
        );

        if( params.accessoryHeight.value != null && params.accessoryHeight.isDisabled === false && params.accessoryWidth.value != null  && params.accessoryWidth.isDisabled === false) {
            coloursBox();

        } else if (params.accessoryLength.value != null && params.accessoryLength.isDisabled === false ) {
            coloursBox();
        }

        HD.product.accesoryActionHiddenCheck();

        function coloursBox() {

            if (isColors > 0 && isColorSelected != undefined) {
                $('.js-submit-btn').removeAttr('disabled');

            } else if (isColors === 0){
                $('.js-submit-btn').removeAttr('disabled');
            }
        }
    },

    sample: function(form, url) {
        event.preventDefault();

        let data = $(form).serialize(),
            loading = true,
            $thisForm = $(form),
            samplesColorLength = $thisForm.find('.form__row-item').length;


            if ( samplesColorLength <= 1) {

                $(form).prepend( '<div class="form__response form__response--error"><p class="mt-0em">Please choose sample</p></div>' );

                setTimeout(function() {
                    $(form).find('.form__response').remove();
                }, 5000);
            } else if (samplesColorLength <= 7) {

                HD.utils.form(form, url, 'colour-sample-form-submission');
            } else {
                $(form).prepend( '<div class="form__response form__response--error"><p class="mt-0em">Maximum number of samples is 6 </p></div>' );

                setTimeout(function() {
                    $(form).find('.form__response').remove();
                }, 5000);
            }
    }
},

HD.filter = {
    wasFilterUse: false,

    accordion: function() {
        var allPanels = $('.js-category-name + .js-category-content');
        var allButtons = $('.js-category-name');
        var allIcons = $('.js-category-icon');

        $(window).resize(function(){
            if (HD.utils.screenSize().x >= 768 ) {
                allPanels.show();
            } else {
                allPanels.hide();
            }
        });

        $('.js-category-name').off();
        $('.js-category-name').click(function() {

            if ( HD.utils.screenSize().x < 768) {
                let $this = $(this);
                let $target =  $this.next();
                let $thisIcon = $this.find('.js-category-icon');

                if (!$target.hasClass('active')){
                   allPanels.removeClass('active').slideUp();
                   allButtons.removeClass('active');
                   allIcons.removeClass('filter__category-icon--active');

                   $target.addClass('active').slideDown();
                   $this.addClass('active');
                   $thisIcon.addClass('filter__category-icon--active');
                } else {
                    $target.removeClass('active').slideUp();
                    $this.removeClass('active');
                    $thisIcon.removeClass('filter__category-icon--active');
                }
            }

            return false;
        });
    },

    showMoreOptions: function(button) {
        let showMoreButton = $(button);
        let moreOptionsContent = showMoreButton.prev();
        let hiddenCAtegory = $('.js-hidden-category-options');

        if (!(showMoreButton.hasClass('js-show-category-options'))) {
            moreOptionsContent.slideToggle();
        } else {
            hiddenCAtegory.slideToggle();
        }
    },

    changeValue: function(formId) {
        let data = $(formId).serialize(),
            url = window.location.origin + window.location.pathname + '?' + HD.filter.combineSerialisedFormData(data);

        HD.filter.wasFilterUse = true;
        Barba.Pjax.goTo( url );

        return false;
    },

    reset: function() {
        let url = window.location.origin + window.location.pathname;
        let filterForm = $('#filter');

        filterForm.find('input[type="checkbox"]:checked').click();

        HD.filter.wasFilterUse = true;

        Barba.Pjax.goTo( url );

        return false;
    },

    combineSerialisedFormData: function(strEscapedSerialisedFormData) {
        let arrFormData = strEscapedSerialisedFormData.split("&"),
            dictCombinedKeys = {},
            i
        for (i = 0; i < arrFormData.length; i++) {
            let arrParam = arrFormData[i].split("="),
                strKey = arrParam[0],
                strValue = arrParam[1];

            if (strKey !== "" && strValue !== "") {
                if (typeof (dictCombinedKeys[strKey]) === "undefined") {
                    dictCombinedKeys[strKey] = strValue;
                }
                else {
                    dictCombinedKeys[strKey] += "," + strValue;
                }
            }
        }

        var arrKeyValuePairs = [];
        for (var key in dictCombinedKeys) {
            if (dictCombinedKeys.hasOwnProperty(key)) {
                arrKeyValuePairs.push(key + "=" + dictCombinedKeys[key]);
            }
        }

        return arrKeyValuePairs.join("&");
    },

    selectEvent: function() {

        $('.js-filter').on('change', function(e) {
            let filterForm = $('#filter').serialize().split("&");
            let categoryAndOptions = [];
            let currentUrl = window.location.href;
            let newUrl;

            if ($(this).attr('checked')) {
                $(this).removeAttr('checked');
            }else {
                $(this).attr('checked', true);
            }

            filterForm.map((item, i, arr) => categoryAndOptions.push(...item.split("=")));

            let secondPartUrlWithFilerOptions = categoryAndOptions
                .filter((value, index, self) => self.indexOf(value) == index)
                .map( (el, index, arr) => {
                    if(isLowerCase(el) && arr.indexOf(el) == 0) {
                        return(`${el}=`)
                    } else if (isLowerCase(el) && arr.indexOf(el) !== 0) {
                        return(`&${el}=`)
                    }
                    return(`${el},`)
                })
                .join("")
                .slice(0,-1)
                .replace(/,&/g,'&');

            function isLowerCase(str){
                return str == str.toLowerCase() && str != str.toUpperCase();
            }

            if (currentUrl.indexOf("?") > 0) {
                currentUrl = currentUrl.substr( 0, currentUrl.indexOf("?"));
            }

            newUrl = `${currentUrl}?${secondPartUrlWithFilerOptions}`;

            history.pushState({url: newUrl}, null, newUrl);

            return callApi({
                url: newUrl, //   newUrl '/mock-data-filter.html'
                method: 'GET',
                onSuccess: filterSuccessCallback
            });
        });
    },

    init: function() {
        this.selectEvent();
    }
};

function filterSuccessCallback(response) {
    let filteredProducts = $('#js-filtering-products');
    let responseRilteredProducts = $(response).find("#js-filtering-products");
    filteredProducts.replaceWith(responseRilteredProducts);
    HD.refreshLazyLoading();
    HD.utils.infiniteScroll( HD.infiniteScrollCallback );
};

HD.inputMask = function() {
    if (document.querySelector('.masked')) {
        new InputMask();
    }
}

HD.product = {

    // HD.product.accesoryActionHiddenCheck();

    checkAccessories: function() {
        if (document.querySelector('[data-page-type="accessory"]')) {
            HD.product.accesoryActionHiddenCheck();
        }
    },

    accesoryActionHiddenCheck: function() {
        let formItems = document.querySelectorAll('[data-page-type="accessory"] .js-accessory-form-item'),
            form = document.querySelectorAll('[data-page-type="accessory"]'),
            isEmpty = false,
            productPrice = $('#product-price'),
            quantity = $('#js-quantity').val()*1,
            startPrice = productPrice.attr('data-start-price')*1,
            basePrice = productPrice.attr('data-base-price')*1,
            priceHeader = $('#price-header'),
            calculatedPrice = quantity * basePrice;

        calculatedPrice = parseFloat(calculatedPrice).toFixed(2);

        // console.log(form);
        // console.table(formItems);

        [].forEach.call(formItems, function(singleItem, index) {
            // console.log('value = ', singleItem.value);

            if (!singleItem.value) {
                isEmpty = true;
            }
        });


        // console.log('isEmpty = ', isEmpty);

        let isColors = $('input[name=colour_id]').length;
        let isColorSelected = $('input[name=colour_id]:checked').val();

        if (isColors > 0 && isColorSelected == undefined) {
            isEmpty = true;
        }

        // console.log('isEmpty = ', isEmpty);

        if (isEmpty === false) {
            $('.js-product-action').removeClass('product-action--hidden');

            priceHeader.text('Total');
            productPrice.text(`£${calculatedPrice}`);
        } else {
            $('.js-product-action').addClass('product-action--hidden');
            priceHeader.text('From');
            productPrice.text(`£${startPrice}`);
            $('#js-quantity').val(1);
        };

        return false;
    },

    removeEmptyOption: function(obj) {
        $(obj).find("option[value='']").hide();
        $(obj).parent().find("label").show();
    },

    resetTooltips: function() {
        let allTooltips = document.querySelectorAll('.tooltip');

        [].forEach.call(allTooltips, function(singleTooltip, index) {
            if (index === 0) {
                singleTooltip.dataset.tooltipFilled = false;
                singleTooltip.dataset.tooltipActive = true;
            } else {
                singleTooltip.dataset.tooltipFilled = false;
                singleTooltip.dataset.tooltipActive = false;
            }
        });
    },

    productActionHiddenCheck: function() {
        let allTooltips = document.querySelectorAll('.tooltip'),
            doorHandle = document.querySelector('#handle_location'),
            // drawer = document.querySelector('input#drawer').checked,
            // drawer = document.querySelector('input#drawer') ? false : document.querySelector('input#drawer').checked,
            drawer = false,
            noHingeTooltips = 0,
            hingeTooltips = 0,
            nhtLength = doorHandle ? 4 : 3,
            // htLength = drawer ? 0 : 4,
            htLength = 0,
            productPrice = $('#product-price'),
            priceHeader = $('#price-header'),
            quantity = $('#js-quantity').val()*1,
            startPrice = productPrice.attr('data-start-price')*1,
            basePrice = productPrice.attr('data-base-price')*1,
            calculatedPrice = quantity * basePrice;

        calculatedPrice = parseFloat(calculatedPrice).toFixed(2);

        if (document.querySelector('input#drawer')) {
            drawer = document.querySelector('input#drawer').checked;
        }
        htLength = drawer ? 0 : 4;

        [].forEach.call(allTooltips, function(singleTooltip, index) {
            let tooltipLoopFilled = (singleTooltip.dataset.tooltipFilled === 'true');
            if (index < 3 || index == 7) {
                if (tooltipLoopFilled) {
                    noHingeTooltips++
                }
            } else {
                if (tooltipLoopFilled && (htLength != 0)) {
                    hingeTooltips++
                }
            }
        });


        try {
            let productType = document.querySelector('[name=type]:checked').value,
                customNumberDependOnType = productType === 'door' ? 0 : 1;
            let heightValue = document.querySelector(`#${productType}_height`).value,
                widthValue = document.querySelector(`#${productType}_width`).value,
                customHeightValue = document.querySelector(`#custom_height_${customNumberDependOnType}`) ? document.querySelector(`#custom_height_${customNumberDependOnType}`).value : void 0,
                customWidthValue = document.querySelector(`#custom_width_${customNumberDependOnType}`) ? document.querySelector(`#custom_width_${customNumberDependOnType}`).value : void 0;

            if ( (heightValue && widthValue) || (customHeightValue && customWidthValue) ) {
                productPrice.text(`£${calculatedPrice}`);
            } else {
                productPrice.text(`£${startPrice}`);
            }
        } catch(e) {
            null;
        }
        console.log('noHingeTooltips: ' + noHingeTooltips);
        console.log('hingeTooltips: ' + hingeTooltips);

        if (noHingeTooltips === nhtLength && $('[name="hinge_holes_required"]:checked').val()*1 === 1 && hingeTooltips < htLength) {
            $('.js-product-action').addClass('product-action--hidden');
            // productPrice.text(`£${startPrice}`);
            priceHeader.text('From');
            $('#js-quantity').val('1');
        }

        if (noHingeTooltips === nhtLength && $('[name="hinge_holes_required"]:checked').val()*1 === 1) {
            $('.js-product-action').addClass('product-action--hidden');

            // productPrice.text(`£${startPrice}`);
            priceHeader.text('From');
            $('#js-quantity').val('1');
        }

        if ((noHingeTooltips < nhtLength) || (hingeTooltips < htLength)) {
            $('.js-product-action').addClass('product-action--hidden');

            // productPrice.text(`£${startPrice}`);
            priceHeader.text('From');
            $('#js-quantity').val('1');
        }

        if ( (noHingeTooltips === nhtLength && $('[name="hinge_holes_required"]:checked').val()*1 === 0) || ((noHingeTooltips + hingeTooltips ) === (nhtLength + htLength)) ) {
            $('.js-product-action').removeClass('product-action--hidden');

            priceHeader.text('Total');
            productPrice.text(`£${calculatedPrice}`);
        }
    },

    tooltip: function(tooltipOrderNumber, customNextTooltip) {
        let tooltip = document.querySelector(`[data-tooltip-order="${tooltipOrderNumber}"]`),
            tooltipOrder = tooltip.dataset.tooltipOrder*1,
            doorHandle = document.querySelector('#handle_location'),
            drawer = document.querySelector('input#drawer').checked,
            activeTooltip = document.querySelector(`[data-tooltip-active="true"]`),
            nextTooltipOrder = tooltipOrder + 1,
            allTooltips = document.querySelectorAll('.tooltip');
        console.log('tooltip');

        HD.product.productActionHiddenCheck();
        if (activeTooltip) {

            if (tooltipOrderNumber*1 === 3 && drawer && doorHandle) {
                nextTooltipOrder = 8;
            } else if (customNextTooltip){
                nextTooltipOrder = customNextTooltip;
            }

            if (tooltipOrderNumber*1 !== activeTooltip.dataset.tooltipOrder*1) {
                tooltip.dataset.tooltipFilled = true;
                HD.product.productActionHiddenCheck();
                return;
            }

            if (tooltipOrderNumber*1 === 2) {
                let productType = document.querySelector('[name=type]:checked').value,
                    customNumberDependOnType = productType === 'door' ? 0 : 1;
                let heightValue = document.querySelector(`#${productType}_height`).value,
                    widthValue = document.querySelector(`#${productType}_width`).value,
                    customHeightValue = document.querySelector(`#custom_height_${customNumberDependOnType}`) ? document.querySelector(`#custom_height_${customNumberDependOnType}`).value : void 0,
                    customWidthValue = document.querySelector(`#custom_width_${customNumberDependOnType}`) ? document.querySelector(`#custom_width_${customNumberDependOnType}`).value : void 0;
    
                let productPrice = $('#product-price'),
                    quantity = $('#js-quantity').val()*1,
                    startPrice = productPrice.attr('data-start-price')*1,
                    basePrice = productPrice.attr('data-base-price')*1,
                    calculatedPrice = quantity * basePrice;

                calculatedPrice = parseFloat(calculatedPrice).toFixed(2);

                if ( (heightValue && widthValue) || (customHeightValue && customWidthValue) ) {
                    productPrice.text(`£${calculatedPrice}`);
                } else {
                    productPrice.text(`£${startPrice}`);
                    return;
                }
            }

            if (tooltipOrderNumber*1 === 6) {
                let topValue = document.querySelector(`#required_hinge_holes_from_top`).value,
                    bottomValue = document.querySelector(`#required_hinge_holes_from_bottom`).value,
                    leftValue = document.querySelector(`#required_hinge_holes_from_left`).value,
                    rightValue = document.querySelector(`#required_hinge_holes_from_right`).value;

                if ((topValue && bottomValue) || (leftValue && rightValue)) {
                    null;
                } else {
                    return;
                }
            }

            tooltip.dataset.tooltipFilled = true;
            tooltip.dataset.tooltipActive = false;
            HD.product.productActionHiddenCheck();

            if (document.querySelector(`[data-tooltip-order="${nextTooltipOrder}"]`)) {
                let nextTooltip = document.querySelector(`[data-tooltip-order="${nextTooltipOrder}"]`),
                    nextTooltipFilled = (nextTooltip.dataset.tooltipFilled === 'true');

                if (nextTooltipFilled) {
                    let found = false;

                    [].forEach.call(allTooltips, function(singleTooltip) {
                        let tooltipLoopFilled = (singleTooltip.dataset.tooltipFilled === 'true');

                        if (found === false) {
                            if ( tooltipLoopFilled === false ) {
                                found = singleTooltip;
                            }
                        }
                    });

                    if (found) {
                        found.dataset.tooltipActive = true;
                    }
                } else {
                    nextTooltip.dataset.tooltipActive = true;
                }

            } else {
                return;
            }
        }

        HD.product.productActionHiddenCheck();
    },

    sendProductHeight: function($this, url) {
        let doorHeight = $($this).val();
        let doorType =  $($this).closest('.js-door-type-content').attr('data-content');
        let doorWidthsSelect = $($this).closest('.js-door-type-content').find('.js-widths-select');
        let pageId = $($this).closest('.js-page-id').attr('data-page-id');

        doorWidthsSelect.find('option:gt(0)').remove();

        $.ajax({
            url: url, //'/mock-data-configurator-width.html'
            method: 'GET',
            data: `type=${doorType}&product_height=${doorHeight}&page_id=${pageId}`,
            dataType: 'json',
            success: function(response) {
                let allWidths = response.widths;
                let priceHeader = $('#price-header');
                let productPrice = $('#product-price');
                let basePrice = productPrice.attr('data-base-price')*1;
                let startPrice = productPrice.attr('data-start-price')*1;

                doorWidthsSelect.removeAttr('disabled');
                doorWidthsSelect.attr('required', 'true');

                $('.js-product-action').addClass('product-action--hidden');

                priceHeader.text('From');
                $('#js-quantity').val('1');

                if (basePrice !== 0) {
                    productPrice.text(`£${basePrice}`);
                } else {
                    productPrice.text(`£${startPrice}`);
                }



                allWidths.forEach ( el =>
                    doorWidthsSelect.append(`<option value="${el}">${el} mm</option>`)
                );
            },
            error: function(response){
                //!coment
            },
        })
    },

    doorDrawerInputsSwitcher: function() {
        let doorTypeButton = $('.js-door-type-btn');
        let productPrice = $('#product-price');
        let startPrice = productPrice.attr('data-start-price')*1;

        doorTypeButton.on('click', function(e) {
            let doorType = $(this).attr('data-name');
            let measurementInputsContent = $(`[data-content="${doorType}"]`);
            let measurementInputForHeight = measurementInputsContent.find('.select--active');
            let allMeasurementInputs = $('.js-door-type-content').find('select');

            $('.js-door-type-content').addClass('hidden');
            measurementInputsContent.removeClass('hidden');

            allMeasurementInputs.removeAttr('required');
            allMeasurementInputs.attr('disabled', 'true');
            allMeasurementInputs.prop('selectedIndex',0);
            allMeasurementInputs.next('label').removeClass('form__label--active');

            measurementInputForHeight.removeAttr('disabled');
            measurementInputForHeight.attr('required', 'true');

            productPrice.attr("data-base-price", startPrice);

            $('#custom_height_0').val('');
            $('#custom_height_1').val('');
            $('#custom_width_0').val('');
            $('#custom_width_1').val('');

            doorType === 'drawer' ? doorTypeButton.closest('form').addClass('js-drawer') : doorTypeButton.closest('form').removeClass('js-drawer');
            doorType === 'drawer' ? $('[data-tooltip-order=8]').html("<span class='tooltip__step'>step 4</span> Door handle") : $('[data-tooltip-order=8]').html("<span class='tooltip__step'>step 8</span> Door handle");

            let jsCustomMeasurementsBtns = document.querySelectorAll('.js-custom-measurements-btn');

            [].forEach.call(jsCustomMeasurementsBtns, function(jsCustomMeasurementsBtn){
                if (jsCustomMeasurementsBtn.classList.contains('js-active')) {
                    $(jsCustomMeasurementsBtn).trigger( 'click' );
                }
            });

            // HD.product.productActionHiddenCheck();

            // $('.js-submit-btn').attr('disabled', true);

            $('input[name=colour_id]').removeAttr('checked');
            $('input[name=colour_id]').next().find('img').removeClass('colors-block__item-img--active');

            $('.js-product-type').text( $(this).text() );


            let measureTooltip = $('[data-tooltip-order=2]'),
                colorTooltip = $('[data-tooltip-order=3]'),
                activeTooltip = $('[data-tooltip-active=true]');

            if (activeTooltip.length) {
                if (activeTooltip[0].dataset.tooltipOrder*1 !== 1) {
                        activeTooltip[0].dataset.tooltipActive = 'false';
                        $('[data-tooltip-order=1]')[0].dataset.tooltipActive = 'true';
                }
            }

            measureTooltip[0].dataset.tooltipFilled = 'false';
            measureTooltip[0].dataset.tooltipActive = 'true';
            colorTooltip[0].dataset.tooltipFilled = 'false';

            HD.product.productActionHiddenCheck();

            HD.product.tooltip(this.getAttribute('data-target-tooltip-order'));
        });
    },

    showCustomMeasurementsInputs: function() {
        var customMeasurementsButton = $('.js-custom-measurements-btn');

        customMeasurementsButton.on('click', function() {
            var $this = $(this);
            $this.next().toggleClass('product-form__two-col--hide');

            if (!($this.next().hasClass('product-form__two-col--hide'))) {
                $this.prev().find('.select').attr('disabled', 'disabled');
                $this.next().find('input').removeAttr('disabled');
                $this.addClass('js-active');
                HD.form.checkParams();

                if ($this.hasClass('js-extra-hinge-holes-btn')) {
                    $this.find('.js-product-form-btn-text').text('Remove extra hinge holes');
                    $this.next().find('input').removeAttr('disabled');
                    $this.find('.product-form__btn-icon').toggleClass('product-form__btn-icon--active');
                } else {
                    $this.find('.product-form__btn-icon').toggleClass('product-form__btn-icon--active');
                    $('.js-door-type-content select').prop('selectedIndex',0);
                }


            } else {
                $this.prev().find('.select--active').removeAttr('disabled');
                $this.next().find('input').attr('disabled', 'disabled');
                $this.prev().find('select').css({'pointer-events': 'auto'});
                $this.removeClass('js-active');

                HD.form.checkParams();

                if ($this.hasClass('js-extra-hinge-holes-btn')) {
                    $this.find('.js-product-form-btn-text').text('Add extra hinge holes');
                    $this.next().find('input').attr('disabled', 'disabled');
                    $this.find('.product-form__btn-icon').toggleClass('product-form__btn-icon--active');
                } else {
                    $this.find('.product-form__btn-icon').toggleClass('product-form__btn-icon--active');
                    $('.js-door-type-content select').prop('selectedIndex',0);
                }
            }
        });
    },

    hingeHolesSwitcher: function() {
        var hingeHolesSwitcher = $('.js-hinge-hols-btn');
        var hingeHolsContent = $('.js-hinge-hols-content');
        var hingeHolesMiddleSwither = $('.js-middle-hinge-holes');
        var doorHangingSelectWrapper = $('.js-door-hanging');

        [].forEach.call(hingeHolesSwitcher, function(el){

            el.addEventListener('click', function() {
                var yesNoBtn = el.getAttribute('id');
                // var doorHangingFields = $('.js-door-hanging-orientation');

                if(yesNoBtn.lastIndexOf('no') === -1) { //YES BTN
                    hingeHolsContent.removeClass('product-form__row--last-el');
                    hingeHolesMiddleSwither.removeClass("product-form__row--hidden");
                    doorHangingSelectWrapper.removeClass("product-form__row--hidden");
                    doorHangingSelectWrapper.find('#hinge_holes_hanging').prop('disabled', false);

                    $('[data-tooltip-order=8]').html("<span class='tooltip__step'>step 8</span> Door handle");

                    if (HD.utils.screenSize().x > 991) {
                        UTILS.scrollToID( '.js-hinge-hols-content', '#configurator');
                    }

                    // HD.product.productActionHiddenCheck();
                    HD.product.tooltip(4);
                } else {
                    // doorHangingFields.hide();
                    // doorHangingFields.find('select').attr('disabled', 'disabled');
                    // doorHangingFields.find('ul').eq(0).find('input').attr('disabled', 'disabled');

                    hingeHolsContent.addClass('product-form__row--last-el');
                    hingeHolesMiddleSwither.addClass("product-form__row--hidden");
                    doorHangingSelectWrapper.addClass("product-form__row--hidden");

                    $('[data-tooltip-order=8]').html("<span class='tooltip__step'>step 5</span> Door handle");


                    // $('#extra_hinge_holes_from_top').attr('disabled', true);
                    // $('#extra_hinge_holes_from_bottom').attr('disabled', true);
                    // $('#extra_hinge_holes_from_left').attr('disabled', true);
                    // $('#extra_hinge_holes_from_right').attr('disabled', true);

                    // doorHangingSelectWrapper.find('.form__label').removeClass('form__label--active');
                    // doorHangingSelectWrapper.find('#hinge_holes_hanging').val($('#hinge_holes_hanging option:first').val());
                    // doorHangingSelectWrapper.find('#hinge_holes_hanging').prop('disabled', true);

                    HD.product.doorHangingFieldsHide();
                    // HD.product.productActionHiddenCheck();
                    HD.product.tooltip(4, 8);
                }
            });
        });
    },

    doorHangingSelectSwitcher: function($this) {
        var doorHangingSelect = $($this);
        var doorHangingSelectVal = doorHangingSelect.val();
        var isHangingOrientationTopBottom = doorHangingSelectVal === 'From Top' || doorHangingSelectVal === 'From Bottom' ? true : false;
        var hingeHolesFieldsForTobBottom = $('.js-door-hanging-orientation[data-door-hanging="top-bottom"]');
        var hingeHolesFieldsForLeftRight = $('.js-door-hanging-orientation[data-door-hanging="left-right"]');

        if(!isHangingOrientationTopBottom) {
            HD.product.hangingOrientationFieldsSwitcher(hingeHolesFieldsForTobBottom, hingeHolesFieldsForLeftRight);
        } else {
            HD.product.hangingOrientationFieldsSwitcher(hingeHolesFieldsForLeftRight, hingeHolesFieldsForTobBottom);
        }
    },

    hangingOrientationFieldsSwitcher: function(hingeHolesFieldsShow, hingeHolesFieldsHide){
        $('.js-door-hanging-orientation-container').show();

        hingeHolesFieldsShow.show();
        hingeHolesFieldsShow.find('select').removeAttr('disabled');
        hingeHolesFieldsShow.find('ul').eq(0).find('input').removeAttr('disabled');

        hingeHolesFieldsHide.hide();
        hingeHolesFieldsHide.find('select').attr('disabled', 'disabled');
        hingeHolesFieldsHide.find('ul').eq(0).find('input').attr('disabled', 'disabled');
    },

    doorHangingFieldsHide: function() {
        var doorHangingContainer = $('.js-door-hanging-orientation-container');
        var doorHangingFields = $('.js-door-hanging-orientation');
        var doorHangingSelectWrapper = $('.js-door-hanging');
        var extraHingeHolesInputAll = $('.js-input-extra_hinge_holes');
        var middleHole = $("input[name='middle_hole_required']");

        doorHangingFields.each(function(i, el){
            $(el).hide();
            $(el).find('select').attr('disabled', 'disabled');
            $(el).find('ul').eq(0).find('input').attr('disabled', 'disabled');
        });

        extraHingeHolesInputAll.each(function(i, el){
            $(el).attr('disabled', true);
        });

        doorHangingSelectWrapper.find('.form__label').hide();
        doorHangingSelectWrapper.find('#hinge_holes_hanging').val($('#hinge_holes_hanging option:first').val());
        doorHangingSelectWrapper.find('#hinge_holes_hanging').prop('disabled', true);

        doorHangingContainer.hide();
        middleHole.prop('checked', false);
    },

    increase (inputId, productPrice = 'product-price') {
        let targetInput = document.querySelector(`#${inputId}`),
            priceEl = document.querySelector(`#${productPrice}`).getAttribute('data-base-price');

        if ( priceEl !== "" ) {
            targetInput.value = (targetInput.value * 1) + 1;
            HD.product.calculatePrice(targetInput.value, productPrice);
        }
    },

    decrease (inputId, productPrice = 'product-price') {
        let targetInput = document.querySelector('#' + inputId),
            priceEl = document.querySelector(`#${productPrice}`).getAttribute('data-base-price'),
            targetInputValue = targetInput.value*1;

        if ( targetInputValue > 1) {
            targetInput.value = (targetInput.value * 1) - 1;
            HD.product.calculatePrice(targetInput.value, productPrice);
        }
    },

    calculatePrice (quantity, productPrice = 'product-price') {
        let priceEl = document.querySelector('#' + productPrice),
            basePrice = priceEl.getAttribute('data-base-price')*1,
            calculatedPrice = 0.00;

        calculatedPrice = quantity * basePrice;

        calculatedPrice = parseFloat(calculatedPrice).toFixed(2);

        priceEl.innerHTML = `£${calculatedPrice}`;
    },

    priceInputOnChangeEvent () {
        if (document.querySelector('#js-quantity')) {
            let priceEl = document.querySelector('#js-quantity');

            priceEl.addEventListener("input", (event) => {
                if(event.target.value < 0) {
                    event.target.value = 1;
                }
                HD.product.calculatePrice(event.target.value);
            }, false);
        }
    },

    changePrice: function( el, productId, url) {
        let $this = $(el);
        let form = $this.closest('form');
        let data = form.serialize();
        let submitButton = form.find('.js-submit-btn');
        let productActionBox = $('.js-product-action');

        let accessoryHeight = $('#accessory_height');
        let accessoryWidth = $('#accessory_width');
        let accessoryLength = $('#accessory_length');

        $.ajax({
            url: url, //'/mock-data-calculate-price.html'
            method: 'GET',
            data: `${data}&product_id=${productId}`,
            dataType: 'json',
            success: function(response) {
                let productPrice = $('#product-price');
                let startPrice = productPrice.attr('data-start-price')*1;
                let updatedProductPrice = response.price;
                let singleProductPrice = response.single_price;

                productPrice.attr('data-base-price', singleProductPrice);
                HD.product.productActionHiddenCheck();

                if (updatedProductPrice === 'NONE') {
                    productPrice.text(`£${startPrice}`);
                } else {
                    productPrice.text(`£${updatedProductPrice}`);
                }

                if ( accessoryHeight.length > 0 || accessoryWidth.length > 0 || accessoryLength.length) {
                    HD.form.checkParamsForAccessories();
                }
            },
            error: function(response){
                let productPrice = $('#product-price');
                let priceHeader = $('#price-header');
                let startPrice = productPrice.attr('data-start-price')*1;
                let errorInfoBox = $('.js-response-error');

                let productType = door;
                let customNumberDependOnType = 0;


                errorInfoBox.find('p').html(response.responseJSON.message); //!uncoment

                try {
                    productType = document.querySelector('[name=type]:checked').value,
                    customNumberDependOnType = productType === 'door' ? 0 : 1;
                } catch(e) {
                    null;
                }

                let customHeightValue = document.querySelector(`#custom_height_${customNumberDependOnType}`).value,
                    customWidthValue = document.querySelector(`#custom_width_${customNumberDependOnType}`).value;

                if ((el.id === `custom_height_${customNumberDependOnType}`) || (el.id === `custom_width_${customNumberDependOnType}`) ) {
                    if (customHeightValue && customWidthValue) {
                        errorInfoBox.fadeIn();
                    }
                } else {
                    errorInfoBox.fadeIn();
                }

                HD.product.productActionHiddenCheck();
                productPrice.attr('data-base-price', startPrice);

                submitButton.attr('disabled', 'true');//!uncoment

                productActionBox.addClass('product-action--hidden');
                priceHeader.text('From');
                $('#js-quantity').val('1');


                setTimeout(function() {
                    errorInfoBox.fadeOut();
                }, 5000);
            },
        })
    },


    configValidation: function( el, productId, url) {
        let $this = $(el);
        let form = $this.closest('form');
        let data = form.serialize();
        let submitButton = form.find('.js-submit-btn');

        let accessoryHeight = $('#accessory_height');
        let accessoryWidth = $('#accessory_width');
        let accessoryLength = $('#accessory_length');

        $.ajax({
            url: url,
            method: 'GET',
            data: `${data}&product_id=${productId}`,
            dataType: 'json',
            success: function() {
                HD.product.productActionHiddenCheck();

                if ( accessoryHeight.length > 0 || accessoryWidth.length > 0 || accessoryLength.length) {
                    HD.form.checkParamsForAccessories();
                }
            },
            error: function(response){
                let errorInfoBox = $('.js-response-error');

                errorInfoBox.find('p').html(response.responseJSON.message); //!uncoment
                errorInfoBox.fadeIn();

                submitButton.attr('disabled', 'true');//!uncoment

                setTimeout(function() {
                    errorInfoBox.fadeOut();
                }, 5000);
            },
        })
    },

    showConfigTable() {
        let configBtn = $('.js-prod-config-btn');
        let ua = window.navigator.userAgent;
        let isIE = ua.search(/(MSIE|Trident|Edge)/);

         [].forEach.call(configBtn, function(el){

            el.addEventListener('click', function() {
                let $this = el;
                let productName = $(this).attr('data-name');

                if( isIE !== -1 ) {
                    $this = $(event.target);
                }

                let productBox = $this.closest('.basket-table__tbody');
                let productBoxConfig = $(productBox).find('.js-prod-config-content')[0];

                $(productBoxConfig).toggleClass('basket-subtable--hidden');

                $(this).toggleClass('basket-table__product-btn--active');

               if(productName) {
                    $(`[data-content="${productName}"]`).toggleClass('basket-subtable--hidden');
                    $(`[data-content="${productName}"]`).parent().toggleClass("product-table__row-item");
               }
            });
        });

    },

    init () {
        this.priceInputOnChangeEvent();
    }
};

HD.shop = {
    bagSummaryElement: $('#js-bag-summary'),

    requestShareButtonLink: function(url) {
        $.ajax({
            url: url,
            method: 'POST',
            success: function(response) {
                document.body.insertAdjacentHTML('beforeend', response);
                setTimeout(function() {
                    document.querySelector('.js-share-link-popup').classList.add('popup-wrapper--show');
                }, 200);
            }
        });
    },

    addToBasket( url, form, productId ) {
        event.preventDefault();

        let data = $(form).serialize();

        if (!productId) {
            return false;
        }

        let quantity = form ? form.querySelector("input[name='quantity']").value : $('#js-quantity').val();

        if ( quantity == undefined) {
            let quantity = 1;
        }

        let hasColour = data.includes('colour_id'); //product_color
        let boxOfColours = $('.js-slider-of-colors');
        let errorBoxForColour = $('.js-error-message');

        $.ajax({
            url: url, // '/api/shop/add-to-bag' - for product; '/api/shop/accessory/add-to-bag' - for accessories
            method: 'POST',
            data: `${data}&product_id=${productId}`,
            dataType: 'json',
            success: function(response) {

                // if(response.redirect_url) {
                //     window.location.href = response.redirect_url;
                // }

                document.querySelector('.l-navbar__basket-qty').innerHTML = response.counter;

                let successInfoBox = $('.js-success-info');
                let hingeHolesMiddleSwither = $('.js-middle-hinge-holes');
                var hingeHolsContent = $('.js-hinge-hols-content');

                hingeHolesMiddleSwither.addClass("product-form__row--hidden");

                successInfoBox.find('p').html(response.message);
                successInfoBox.fadeIn();

                form.reset();

                $('#door_width').attr('disabled', 'disabled');
                $('#drawe_width').attr('disabled', 'disabled');

                $('#hinge_holes_hanging').find("option[value='']").show();
                $('#hinge_holes_hanging').parent().find("label").hide();

                $('#handle_location').find("option[value='']").show();
                $('#handle_location').parent().find("label").hide();



                $('.colors-block__item-img').removeClass('colors-block__item-img--active');
                $('.js-hinge-holes-field').hide();
                hingeHolsContent.addClass('product-form__row--last-el');

                let jsCustomMeasurementsBtns = document.querySelectorAll('.js-custom-measurements-btn');

                [].forEach.call(jsCustomMeasurementsBtns, function(jsCustomMeasurementsBtn){
                    if (jsCustomMeasurementsBtn.classList.contains('js-active')) {
                        $(jsCustomMeasurementsBtn).trigger( 'click' );
                    }
                });


                HD.product.resetTooltips();
                HD.product.productActionHiddenCheck();

                UTILS.scrollToID( 'body' );

                setTimeout(function() {
                    successInfoBox.fadeOut();
                }, 5000);

                let productPrice = $('#product-price'),
                    startPrice = productPrice.attr('data-start-price')*1;

                $('#js-quantity').val('1');
                productPrice.text(`£${startPrice}`);
                productPrice.attr("data-base-price", startPrice);

                HD.product.checkAccessories();

            },
            error: function(response){
                let errorInfoBox = $('.js-response-error');

                errorInfoBox.find('p').html(response.responseJSON.message); //!uncoment
                errorInfoBox.fadeIn();

                setTimeout(function() {
                    errorInfoBox.fadeOut();
                }, 5000);
            },
        })
    },

    removeProductFromBasket(productId) {
        if (!productId) {
            return false;
        }

        return callApi({
            url: '/api/shop/remove-product',
            method: 'DELETE',
            data: `product_pivot_id=${productId}`,
            onSuccess: bagSummarySuccessCallback
        });
    },

    happyCareToggle(btn, isFromStep = false) {
        let happyCareForm = $(btn).closest('form');
        let data = happyCareForm.serialize();
        let happyCareCheckbox = happyCareForm.find("input[type='checkbox']");
        let happyCareEnabled = data.indexOf("&");

        if (happyCareEnabled != -1) {
            data = data.split('&').slice(0,-1)[0]
        }

        if ( data == "happy_care_enabled=no") {
            happyCareForm.find(".form__checkbox-label").addClass("hidden");

            if (isFromStep) {
                data = `${data}&is_from_step=1`;
            }

            return callApi({
                url: '/api/shop/toggle-happy-care',
                method: 'POST',
                data,
                onSuccess: bagSummarySuccessCallback
            });
        } else {
            happyCareForm.find(".form__checkbox-label").removeClass("hidden");

            if (happyCareCheckbox.is(":checked")) {
                happyCareCheckbox.click();
            }
        }
    },

    happyCareConfirmConditions(checkboxEl, isFromStep = false) {
        let $thisCheckbox = $(checkboxEl);
        let data = $thisCheckbox.closest('form').serialize();

        if (data == "happy_care_enabled=yes&terms_and_conditions=on") {
            if (isFromStep) {
                data = `${data}&is_from_step=1`;
            }

            return callApi({
                url: '/api/shop/toggle-happy-care',
                method: 'POST',
                data,
                onSuccess: bagSummarySuccessCallback
            });
        }
    },

    continueToCheckoutValidation(url) {
        let happyCareBtnYesIsChecked = $("#happy_care_enabled_yes").is(":checked");
        let happyCareBtnNoIsChecked = $("#happy_care_enabled_no").is(":checked");
        let termAndConditionsIsChecked = $('#terms-and-conditions').is(":checked");

        if ( happyCareBtnYesIsChecked == true && termAndConditionsIsChecked == false) {
            event.preventDefault();

            HD.utils.scrollToID('.js-total-box');
            $(".js-basket-total").prepend( '<div class="form__response form__response--error mb-10em"><p>To proceed with Happy Care you must agree to the Terms and Conditions.</p></div>' );

            setTimeout(function() {
                $(".js-basket-total").find('.form__response').remove();
            }, 5000);

            if (!url) {
                return false;
            }
        } else {
            if (url) {
                window.location.href = url;
            }
        }
    },

    changeQuantity( productId, increment) {
        increment = increment || false;

        if (!productId) {
            return false;
        }

        return callApi({
            url: '/api/shop/change-quantity', // /mock-data-basket.html
            method: 'POST', //GET
            data: `product_pivot_id=${productId}&increment=${increment}`,
            onSuccess: bagSummarySuccessCallback
        });
    },

    changeManualQuantity(productId, amount) {

        if ( (amount*1) % 1 !== 0 ) {
            return false;
        }

        if (!productId) {
            return false;
        }

        return callApi({
            url: '/api/shop/change-quantity',
            method: 'POST',
            data: `product_pivot_id=${productId}&amount=${amount}`,
            onSuccess: bagSummarySuccessCallback
        });
    },

    applyDiscount (promoCode, type) {

        if (!promoCode) {
            return false;
        }

        return callApi({
            url: '/api/shop/apply-discount',
            method: 'POST',
            data: `promo_code=${promoCode}&type=${type}`,
            onSuccess: bagSummarySuccessCallback,
            errorCallback: promoCodeFailed
        });
    },

    removeDiscount (type) {

        return callApi({
            url: '/api/shop/remove-discount',
            method: 'DELETE',
            data: `type=${type}`,
            onSuccess: bagSummarySuccessCallback
        });
    },

    restoreAutomaticDiscount (type) {

        return callApi({
            url: '/api/shop/restore-discount',
            method: 'POST',
            data: `type=${type}`,
            onSuccess: bagSummarySuccessCallback
        });
    },

    quickAddProduct (productPivotId, accessoryId, form) {
        let quantity = $(form).find("input[name='quantity']").val();

        if( quantity == 0) {
            return false;
        }

        return callApi({
            url: '/api/shop/accessory/quick-add', // '/mock-data-added-products.html'
            method: 'POST', //'GET'
            data: `product_pivot_id=${productPivotId}&accessory_id=${accessoryId}&quantity=${quantity}`,
            // onSuccess: HD.shop.quickAddProductCallback
            onSuccess: bagSummarySuccessCallback
        });
    },

    paymentType (obj) {
        const PAYPAL = 'PAYPAL',
            CREDITCARD = 'CREDITCARD';

        if (obj === CREDITCARD) {
            $('#creditcard-form').fadeIn();
            $('#paypal-btn').fadeOut(function() {
                $('#creditcard-btn').removeClass('disabled');
                $('#creditcard-btn').fadeIn();

            })

        } else {
            $('#creditcard-form').fadeOut();

            $('#creditcard-btn').fadeOut(function() {
                $('#paypal-btn').fadeIn();
            })
        }

        $('#form-address-wrapper').fadeIn();
    },

    quickAddProductCallback (response) {
        document.querySelector('.l-navbar__basket-qty').innerHTML = response.counter;
        document.getElementById("basket_sum").innerHTML = response.basket_summary; // response
    },

    acceptTerms(isChecked) {
        const submitButton = document.getElementById('confirm-order');

        if (!submitButton) {
            return;
        }

        if (isChecked) {
            submitButton.removeAttribute('disabled');
        } else {
            submitButton.setAttribute('disabled', true);
        }
    },

    submitPayment({amount, currency}) {
        let form = document.getElementById('js-checkout-step-2');
        // const MAX_POLL_COUNT = 10;
        // let pollCount = 0;

        const billingDetails = {
            name: `${document.getElementById('fname').value} ${document.getElementById('lname').value}`,
            address: {
                line1: document.getElementById('street_number').value,
                line2: document.getElementById('street_name').value,
                city: document.getElementById('postal_town').value,
                postal_code: document.getElementById('postal_code').value,
                country: document.getElementById('country').value,
            }
        };

        $('#js-checkout-step-2 .loading').fadeIn(410, function() {
            stripe.createPaymentMethod(
                'card',
                cardNumber,
                {
                    billing_details: billingDetails
                }
            ).then(function(result) {
                if (result.error) {
                    // Show error in payment form
                    showErrorMessage({message: result.error.message});
                    hideLoadingLayer();
                } else {
                    // Otherwise send paymentMethod.id to your server (see Step 2)
                    $.ajax({
                        url: '/api/shop/payment',
                        method: 'POST',
                        contentType: 'application/json',
                        data: JSON.stringify({ payment_method_id: result.paymentMethod.id }),
                        error: result => {
                            handleServerResponse(result.responseJSON);
                        },
                        success: result => {
                            handleServerResponse(result);
                        }
                    });
                }
            });
        });

        function handleServerResponse(response) {
            if (response.error) {
                // Show error from server on payment form
                showErrorMessage({message: response.message});
                hideLoadingLayer();

            } else if (response.requires_3ds_secure) {
                // Use Stripe.js to handle required card action
                stripe.handleCardAction(
                    response.payment_intent_client_secret
                ).then(function(result) {
                    if (result.error) {
                        // Show error in payment form
                        showErrorMessage({message: result.error.message});
                        hideLoadingLayer();
                    } else {
                        // The card action has been handled
                        // The PaymentIntent can be confirmed again on the server
                        $.ajax({
                            url: '/api/shop/payment',
                            method: 'POST',
                            contentType: 'application/json',
                            data: JSON.stringify({ payment_intent_id: result.paymentIntent.id }),
                            error: result => {
                                handleServerResponse(result.responseJSON);
                            },
                            success: result => {
                                form.submit();
                            }
                        });
                    }
                });
            } else {
                form.submit();
            }
        };

        function showErrorMessage({message}) {
            let errorElement = document.getElementById('card-errors');
            errorElement.textContent = message;
            $('.form__response.form__response--error').removeClass('hidden');
        }

        function hideLoadingLayer() {
            $('#js-checkout-step-2 .loading').fadeOut();
        }
    },
};

HD.closeErrorMessage = function(el) {
    let $this = $(el);
    let boxOfColours = $('.js-slider-of-colors');

    boxOfColours.removeClass('colors-block__box--bordered-red');
    $this.addClass('colors-block__error--none');
}

function callApi(options) {

    if (!options.url) {
        return false;
    }

    var ajaxDetails = {
        url: options.url,
        method: options.method || 'GET'
    };

    if (options.data) {
        ajaxDetails.data = options.data;
    }

    $.ajax(ajaxDetails)
        .then(function(response) {
            if (options.onSuccess) {
                options.onSuccess(response);
            }
        }, function(response) {
            if (options.errorCallback) {
                options.errorCallback(response);
            }
        });
    return false;
}

function bagSummarySuccessCallback(response) {
    let bagSummaryElement = $('#js-bag-summary');

    bagSummaryElement.html(response);
    HD.utils.scrollToID('.js-total-box');
    runUniversalScripts();
    HD.product.showConfigTable();
};

function promoCodeFailed(response) {

    if (response.responseJSON.error === true) {

        if ( $('.promo-code__error').length === 0 ) {
            $('.promo-code').prepend( `<p class="promo-code__error">${response.responseJSON.message}</p>` );  //
        } else {
             $('.promo-code__error').html(response.responseJSON.message);
        }
    }
};

HD.product.tile = {
    increase (el) {
        let targetInput = $(el).prev('.js-qty')[0];
        let productPrice = $(targetInput).closest('.js-product-tile').find('.js-product-price')[0].getAttribute('data-price');
        let elWithPrice = $(targetInput).closest('.js-product-tile').find('.js-product-price-sum')[0];

        targetInput.value = (targetInput.value * 1) + 1;
        HD.product.tile.calculatePrice(targetInput.value, productPrice, elWithPrice);
    },

    decrease (el) {
        let targetInput = $(el).next('.js-qty')[0],
            targetInputValue = targetInput.value*1;
        let productPrice = $(targetInput).closest('.js-product-tile').find('.js-product-price')[0].getAttribute('data-price');
        let elWithPrice = $(targetInput).closest('.js-product-tile').find('.js-product-price-sum')[0];

        if ( targetInputValue >= 1) {
            targetInput.value = (targetInput.value * 1) - 1;
            HD.product.tile.calculatePrice(targetInput.value, productPrice, elWithPrice);
        }
    },

    calculatePrice (quantity, productPrice, elwithPrice) {
        let priceEl = elwithPrice,
            basePrice = productPrice*1,
            calculatedPrice = 0.00;

        calculatedPrice = quantity * basePrice;
        calculatedPrice = parseFloat(calculatedPrice).toFixed(2);
        priceEl.innerHTML = `£${calculatedPrice}`;

        if ( calculatedPrice > 0) {
            $(priceEl).removeClass('btn-two-piece__item--hide');
            $(priceEl).next().removeClass('btn-two-piece__item--centered');
        } else {
            $(priceEl).addClass('btn-two-piece__item--hide');
            $(priceEl).next().addClass('btn-two-piece__item--centered');
        }
    }
}


HD.utils.imgGallery = function imgGallery() {

    $('.js-thumb-item').on('click', function() {
        $('.js-thumb-preview').attr('src', $(this).find('img').attr('data-img-url'));
        $('.js-thumb-preview').attr('data-popup-img', $(this).find('img').attr('data-popup-img'));

        $('.js-thumb-item').removeClass('active');
        $(this).addClass('active');

        return false;
    });
};

HD.utils.showProductInRightColor = function showProductInRightColor() {
    let mainImage = $('.js-product-img').find('img');

    $('.js-selected-color').on('click', function(event) {
        event.stopPropagation();
        let productColorImg =  $(this).attr('data-product-img-url');
        let productColorForPopup = $(this).attr('data-new-popup-img');
        let boxOfColours = $('.js-slider-of-colors');
        let errorBoxForColour = $('.js-error-message');

        mainImage.attr('src', productColorImg);
        mainImage.attr('data-popup-img', productColorForPopup);

        $('.colors-block__item-img').removeClass('colors-block__item-img--active');
        $(this).find('.colors-block__item-img').addClass('colors-block__item-img--active');

        $('.js-selected-color').find("input").removeAttr('checked');
        $(this).find("input").attr('checked', 'checked');

        boxOfColours.removeClass('colors-block__box--bordered-red');
        errorBoxForColour.addClass('colors-block__error--none');

        if ($('[data-page-type="accessory"]').length) {
            HD.form.checkParamsForAccessories();
        } else {
            HD.product.tooltip('3');
        }
    });
};

HD.addToFormColorFromPopup = function () {
    event.preventDefault();
    $('.js-selected-color-from-popup').find('input').attr('value', productColorNumber);
    $('.js-selected-color-from-popup').find('input').attr('checked', 'checked');
};

HD.carousel = {
    productColor: function() {
        let carouselSettings = {
            margin: 20,
            nav: true,
            dots: false,
            responsiveClass:true,
            responsive: {
                0: {
                    items: 1
                },
                376: {
                    items: 2
                },
                640: {
                    items: 4
                },
                991: {
                    items: 2
                },
                1250: {
                    items: 3
                },
                1600: {
                    items: 4
                },
                1921: {
                    items: 5
                },
            }
        };

        if ($('.js-slider-of-colors').length) {
            $('.js-slider-of-colors').owlCarousel(carouselSettings);
        }
    },
    product: function() {
        let carouselSettings = {
            margin: 10,
            nav: true,
            dots: false,
            responsiveClass:true,
            responsive: {
                0: {
                    items: 1
                },
                375: {
                    items: 2
                },
                567: {
                    items: 3
                },
                768: {
                    items: 4
                },
                991: {
                    items: 2
                },
                1280: {
                    items: 3
                },
                1600: {
                    items: 4
                },
            }
        };

        if ($('.js-slider-of-products').length) {
            $('.js-slider-of-products').owlCarousel(carouselSettings);
        }
    },
};

HD.openPopupForProduct = function() {
    var productImage =$('.js-product-img');
    var popupContainer = $('.js-product-popup');
    var popupImage = popupContainer.find('img');
    var popupCloseBtn = popupContainer.find('.popup__close');

    productImage.on("click", function() {
        var windowWidth = $(window).width();
        var imageBackground = $(this).find('img').attr('data-popup-img'); //src

        popupImage.attr('src', imageBackground);
        popupContainer.addClass('popup-wrapper--show');

        function popupHide() {
            popupContainer.removeClass('popup-wrapper--show');
            setTimeout( () =>  popupImage.removeAttr('src'), 600);
        };

        popupCloseBtn.on("click", function() {
            popupHide();
        });

        $(document).mouseup(function (e){
            if (!$('.popup--prod').is(e.target) && $('.popup--prod').has(e.target).length === 0 && $('.popup--prod').parent().hasClass('popup-wrapper--show')){
                popupHide();
            }
        });
    });
};

HD.openPopup = function() {
    var popupButton = $('.js-open-popup');
    var allColours = $('.js-product-color');

    popupButton.on("click", function() {
        var popupButtonDataName = $(this).attr('data-name');
        var popupDataContent = $('[data-content="' + popupButtonDataName + '"]');
        var popupContentCloseBtn = popupDataContent.find('.popup__close');

        if (popupButtonDataName !== "choose-sample") {
            popupDataContent.addClass('popup-wrapper--show');
        } else if ( popupButtonDataName == "choose-sample"  && HD.sampleColoursTable.length <= 5) {
            popupDataContent.addClass('popup-wrapper--show');
            popupDataContent.removeClass('hidden');

            if ($(this).hasClass("hidden")) {
                $(this).removeClass("hidden");
            } else if (HD.sampleColoursTable.length == 5) {
                $(this).addClass("hidden");
            }
        }
        popupContentCloseBtn.on("click", function() {
            popupDataContent.removeClass('popup-wrapper--show');

            if ( HD.sampleColoursTable.length <= 5 &&  $('.js-open-popup').hasClass("hidden")) {
                $('.js-open-popup').removeClass("hidden");
            }
        });

        if (popupButtonDataName === "view-all-colors") {
            [].forEach.call(allColours, function(el){
                el.addEventListener('click', function() {
                    allColours.removeClass('colors-block__item--active');
                    $(this).addClass('colors-block__item--active');
                    productColorNumber = $(this).find('input').attr('value');
                    popupDataContent.removeClass('popup-wrapper--show');
                });
            });
        }

        $(document).mouseup(function (e){
            if (!$('.popup').is(e.target) && $('.popup').has(e.target).length === 0 && $('.popup').parent().hasClass('popup-wrapper--show')){
                $('.popup').parent().removeClass('popup-wrapper--show');
            }
        });
    });
};

HD.sampleColoursTable = [];

HD.addSampleColorToForm = function() {
    var sampleColours = document.querySelectorAll('.js-sample-color');
    var sampleColoursPopup = $('.js-sample-color').closest('.popup-wrapper');

    var formRowForColor = $('.js-form-row');

    [].forEach.call(sampleColours, function(el){
        el.addEventListener('click', function() {
            let $thisInput = $(el).prev('.colors-block__item-input')[0];
            let $thisColourId = $(el).prev('.colors-block__item-input')[0].getAttribute('id');
            let $thisColourImg = $(el).find('img')[0].getAttribute('src');
            let $thisColourName =  $(el).find('span').text();

            if (!($($thisInput).is(":checked"))) {
                HD.sampleColoursTable.push($thisColourId);
                $(el).find('img').addClass("colors-block__item-img--active");

                formRowForColor.prepend(`
                    <div class="form__row-item">
                    <div class="form-sample bg-gray">
                        <input type="checkbox" name="${$thisColourId}" checked>
                        <button class="form-sample__btn" onclick="HD.removeSampleColorFromForm(this);" type="button">
                            <svg class="" width="24" height="24">
                                <use xlink:href="/images/icons.svg#icon-cancel"></use>
                            </svg>
                        </button>

                        <div class="form-sample__text">
                            <p class="font-xs text-upper">Choose sample*</p>
                            <p>${$thisColourName}</p>
                        </div>

                        <div class="form-sample__img">
                            <img src="${$thisColourImg}">
                        </div>
                    </div>
                </div>
                `);

            } else {
                let indexOfColor = HD.sampleColoursTable.indexOf($thisColourId);
                HD.sampleColoursTable.splice(indexOfColor, 1);
                $(el).find('img').removeClass("colors-block__item-img--active");

                formRowForColor.find(`input[name="${$thisColourId}"]`).closest(".form__row-item").remove();
            }


            if (HD.sampleColoursTable.length >= 1) {
                $(".js-sample-btn").text("Add another sample");
            } else {
                $(".js-sample-btn").text("Choose sample");
            }

            sampleColoursPopup.removeClass("popup-wrapper--show");
            sampleColoursPopup.addClass("hidden");

        });
    });
};

HD.removeSampleColorFromForm = function(el) {
    let sampleColorCancelBtn = $(el);
    let smapleColorId = $(el).prev()[0].getAttribute('name');
    let colorInPopup = $(`#${smapleColorId}`);

    let indexOfColor = HD.sampleColoursTable.indexOf(smapleColorId);
    HD.sampleColoursTable.splice(indexOfColor, 1);

    sampleColorCancelBtn.closest(".form__row-item").remove();

    colorInPopup.click();
    colorInPopup.parent().find('img').removeClass("colors-block__item-img--active");

    if (HD.sampleColoursTable.length <= 5 && $('.js-open-popup').hasClass("hidden") ) {
        $('.js-open-popup').removeClass("hidden");
    }
};

HD.refinementBySelect = function() {
    $('.js-filter-ajax').on('change', function(e) {
        const self = this;
        let val = $(this).val();

        if (val !== '-1') {
            let filterType = self.getAttribute('data-filter-type') || 'category',
                selectedOption = $(self).find(":selected"),
                slug = val,
                withoutTopicRefinement = selectedOption.data('no-topics') || false;

            HD.refinement.filterBy(filterType, slug, withoutTopicRefinement);
        }
    });
};

HD.openSocialLoginPopup = function(options) {
    if (!options.authUrl) {
        return false;
    }

    const signinWindow = window.open(options.authUrl, "SignIn", "width=780,height=410,toolbar=0,scrollbars=0,status=0,resizable=0,location=0,menuBar=0,left=" + 500 + ",top=" + 200);
};

HD.equalHeight = function() {
    $('.js-equal-height').matchHeight();
    $('.js-equal-height-2').matchHeight();
};

HD.refreshLazyLoading = function() {
    if (window['lazyLoadInstance'] !== undefined) {
        lazyLoadInstance.update();
    }
};

HD.infiniteScrollCallback = function() {
    HD.refreshLazyLoading();
    HD.utils.animationedSections();
    HD.equalHeight();
};

HD.carousel = {
    productColor: function() {
        let carouselSettings = {
            margin: 20,
            nav: true,
            dots: false,
            responsiveClass:true,
            responsive: {
                0: {
                    items: 1
                },
                376: {
                    items: 2
                },
                640: {
                    items: 4
                },
                991: {
                    items: 2
                },
                1250: {
                    items: 3
                },
                1600: {
                    items: 4
                },
                1921: {
                    items: 5
                },
            }
        };
        if ($('.js-slider-of-colors').length) {

            if (HD.utils.screenSize().x > 991) {
                $('.js-slider-of-colors').owlCarousel(carouselSettings);
            } else {
                $('.js-slider-of-colors').removeClass('owl-carousel');
                $('.js-slider-of-colors').addClass('horizontal-scroll');
            }
        }
    },
    product: function() {
        let carouselSettings = {
            margin: 10,
            nav: true,
            dots: false,
            responsiveClass:true,
            responsive: {
                0: {
                    items: 1
                },
                375: {
                    items: 2
                },
                567: {
                    items: 3
                },
                768: {
                    items: 4
                },
                991: {
                    items: 2
                },
                1280: {
                    items: 3
                },
                1600: {
                    items: 4
                },
            }
        };
        if ($('.js-slider-of-products').length) {
            $('.js-slider-of-products').owlCarousel(carouselSettings);
        }
    },
};





HD.geo = {
    placeSearch: '',
    autocomplete: '',
    componentForm: {
        street_number: 'short_name',
        route: 'long_name',
        locality: 'long_name',
        postal_town: 'long_name',
        postal_code: 'short_name',
        administrative_area_level_1: 'long_name',
        administrative_area_level_2: 'long_name'
    },
    wasLoaded: false,

    showMore () {
        if ( document.querySelector('.js-show-more') ) {
            var showMoreButtons = document.querySelectorAll('.js-show-more');

            [].forEach.call(showMoreButtons, function(showMoreButton) {
                showMoreButton.addEventListener("click", function( event ) {
                    event.preventDefault();
                    let targetId = event.currentTarget.getAttribute('data-target-id');
                    document.querySelector('#' + targetId).style.display = 'block';
                    this.parentNode.removeChild(this);

                    if (document.querySelector('#address-autocomplete')) {
                        document.querySelector('#address-autocomplete').parentNode.removeChild(document.querySelector('#address-autocomplete'));
                    }
                }, false);
            });
        }
    },

    loadScript: function(map) {
        if (HD.geo.wasLoaded === false) {

            var protocol = location.protocol,
            urlApi = '';

            if (protocol === 'http:') {
                urlApi = 'maps.googleapis.com';
            } else {
                urlApi = 'maps-api-ssl.google.com';
            }

            var script = document.createElement('script');
            script.type = 'text/javascript';
            script.async = true;
            script.defer = true;
            script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyDwJhLkc8ZS_ETZeU59qepyV4t85NX14h8&language=en&libraries=places&callback=HD.geo.initAutocomplete';

            document.body.appendChild(script);

            HD.geo.wasLoaded = true;
        } else {
            HD.geo.initAutocomplete();
        }
    },

    initAutocomplete () {
        // Create the autocomplete object, restricting the search to geographical
        // location types.
        HD.geo.autocomplete = new google.maps.places.Autocomplete(
            /** @type {!HTMLInputElement} */(document.getElementById('autocomplete')),
            {
                types: ['geocode'],
                componentRestrictions: {country: "uk"}
            }
        );

        // When the user selects an address from the dropdown, populate the address
        // fields in the form.
        HD.geo.autocomplete.addListener('place_changed', HD.geo.fillInAddress);

        google.maps.event.addDomListener(document.getElementById('autocomplete'), 'keydown', function(event) {
            if (event.keyCode === 13) {
                event.preventDefault();
            }
        });
    },

    fillInAddress () {
        // Get the place details from the autocomplete object.
        var place = HD.geo.autocomplete.getPlace();

        if ( document.querySelector('.js-show-more') ) {
            document.querySelector('#more-form').style.display = 'block';
            document.querySelector('.js-show-more').parentNode.removeChild(document.querySelector('.js-show-more'));
            document.querySelector('#enter-your-address').style.display = 'none';
        }

        for (var component in HD.geo.componentForm) {
            if (HD.geo.componentForm.hasOwnProperty(component)) {
                if (document.getElementById(component)) {
                    document.getElementById(component).value = '';
                    document.getElementById(component).disabled = false;
                }
            }
        }

        // Get each component of the address from the place details
        // and fill the corresponding field on the form.
        for (var i = 0; i < place.address_components.length; i++) {
            var addressType = place.address_components[i].types[0];
            // Hatch Warren Lane, Basingstoke, Hampshire

            if (HD.geo.componentForm[addressType]) {

                var val = place.address_components[i][HD.geo.componentForm[addressType]];

                if ( document.getElementById(addressType) ) {
                    document.getElementById(addressType).value = val;
                }

                // if (addressType === 'postal_town') {
                //     document.getElementById('locality').value = val;
                // }
            }
        }

        HD.form.inputLabel(".js-inputLabel");
    },

    geolocate () {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function(position) {
                var geolocation = {
                lat: position.coords.latitude,
                lng: position.coords.longitude
                };
                var circle = new google.maps.Circle({
                center: geolocation,
                radius: position.coords.accuracy
                });
                HD.geo.autocomplete.setBounds(circle.getBounds());
            });
        }

    }
};





HD.browserZoomLevelDetector = function() {
    let browserZoomLevel = Math.round(window.devicePixelRatio * 100);
    var retina = window.devicePixelRatio > 1;

    if ( browserZoomLevel == 110 ) {
        $(".l-navbar__logo-icon").addClass("l-navbar__logo-icon--zoom-110");
        $(".l-navbar__logo-icon").removeClass("l-navbar__logo-icon--zoom-125");
        $(".l-navbar__logo-icon").removeClass("l-navbar__logo-icon--zoom-150");
        $(".l-navbar__logo-icon").removeClass("l-navbar__logo-icon--zoom-175");

    } else if ( browserZoomLevel == 125) {
        $(".l-navbar__logo-icon").addClass("l-navbar__logo-icon--zoom-125");
        $(".l-navbar__logo-icon").removeClass("l-navbar__logo-icon--zoom-110");
        $(".l-navbar__logo-icon").removeClass("l-navbar__logo-icon--zoom-175");
    } else if (browserZoomLevel == 150){
        $(".l-navbar__logo-icon").addClass("l-navbar__logo-icon--zoom-150");
        $(".l-navbar__logo-icon").removeClass("l-navbar__logo-icon--zoom-110");
        $(".l-navbar__logo-icon").removeClass("l-navbar__logo-icon--zoom-125");
        $(".l-navbar__logo-icon").removeClass("l-navbar__logo-icon--zoom-175");

    } else if ( browserZoomLevel == 175 && !retina) {
        $(".l-navbar__logo-icon").addClass("l-navbar__logo-icon--zoom-175");
        $(".l-navbar__logo-icon").removeClass("l-navbar__logo-icon--zoom-110");
        $(".l-navbar__logo-icon").removeClass("l-navbar__logo-icon--zoom-125");
        $(".l-navbar__logo-icon").removeClass("l-navbar__logo-icon--zoom-150");

    } else if (retina) {

        if ( browserZoomLevel == 220 || browserZoomLevel == 300) {
            $(".l-navbar__logo-icon").addClass("l-navbar__logo-icon--retina");

        } else if (browserZoomLevel == 180) {
            $(".l-navbar__logo-icon").addClass("l-navbar__logo-icon--retina-90");

        } else {
            $(".l-navbar__logo-icon").removeClass("l-navbar__logo-icon--retina");
            $(".l-navbar__logo-icon").removeClass("l-navbar__logo-icon--retina-90");
        }

    }else {
        $(".l-navbar__logo-icon").removeClass("l-navbar__logo-icon--zoom-110");
        $(".l-navbar__logo-icon").removeClass("l-navbar__logo-icon--zoom-175");
    }
};

HD.removeStripeBannerByClick = function() {
    const stripeCloseButton = document.querySelector('.js-stripe-btn'),
        stripe = document.querySelector('.js-stripe'),
        navbar = document.querySelector('.js-navbar');

    if (!stripeCloseButton || !stripe || !navbar) {
        return;
    }

    const stripeCloseButtonCookie = stripeCloseButton.getAttribute('data-cookie-name') || 'strip-bar-closed';

    stripeCloseButton.addEventListener('click', () => {
        $(stripe).slideUp(300);
        navbar.classList.remove('l-navbar--push-down');
        document.querySelector('#barba-wrapper').classList.add('no-stripe');

        var expires = "";
        document.cookie = stripeCloseButtonCookie + "=" + true + expires + "; path=/";

        if (window.stickySidebarInstance) {
            setTimeout(() => {
                window.stickySidebarInstance.updateSticky();
            }, 310);
        }
    });
};

function runUniversalScripts() {
    HD.form.inputLabel(".js-inputLabel");
    HD.form.selectIconUp(".js-select");
    HD.form.cardNumberValidation();
    HD.refinementBySelect();
    HD.openPopup();
    HD.utils.animationedSections();
    HD.equalHeight();
    HD.refreshLazyLoading();
}

function runWebsiteScripts() {
    runUniversalScripts();

    HD.utils.hashAnchorClick();
    HD.filter.accordion();
    HD.utils.magnific();
    HD.product.showCustomMeasurementsInputs();
    HD.product.hingeHolesSwitcher();
    HD.product.doorDrawerInputsSwitcher();
    HD.product.init();
    HD.carousel.productColor();
    HD.carousel.product();
    HD.utils.imgGallery();
    HD.openPopupForProduct();
    HD.utils.showProductInRightColor();
    HD.product.showConfigTable();
    HD.shop.removeProductFromBasket();
    HD.filter.init();
    HD.browserZoomLevelDetector();
    HD.addSampleColorToForm();
    HD.inputMask();
    HD.removeStripeBannerByClick();

    if ( document.getElementById('autocomplete') ) {
        HD.geo.showMore();
        HD.geo.loadScript();
    }

    HD.utils.infiniteScroll( HD.infiniteScrollCallback );

    if(/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
        $(".l-navbar__logo-icon").addClass("l-navbar__logo-icon--safari");
    } else if (navigator.userAgent.indexOf("Firefox") > 0) {
        $(".l-navbar__logo-icon").addClass("l-navbar__logo-icon--firefox");
    }

    HD.product.checkAccessories();
}

function trackPageGTM() {
    if (window['dataLayer'] !== undefined) {
        let pagePath = window.location.pathname;

        window.dataLayer.push({
            'event': 'content-view',
            'content-name': pagePath
        });
    }
}

window.addEventListener('resize', function() {
    HD.browserZoomLevelDetector();

    setTimeout(function(){
        Waypoint.refreshAll();
    }, 80);
}, false);

/*
    * -----------------------------------------------------------
    * // Dynamic loading via Barba.js
    *
*/

var lastElementClicked;

var FadeTransition = Barba.BaseTransition.extend({
    start: function() {
        Promise
            .all([this.HideOldPage(), this.newContainerLoading])
            .then(this.showNewPage.bind(this));
    },

    HideOldPage: function() {
        var deferred = Barba.Utils.deferred();

        $('.barba-animation-left').addClass('leave');
        $('.barba-animation-right').addClass('leave');
        
        setTimeout(function(){
            deferred.resolve();
        }, 400);

        return deferred.promise;
    },

    showNewPage: function() {
        this.done();

        var _this = this;

        $(window).scrollTop(0);
    }
});

Barba.Pjax.getTransition = function() {
    return FadeTransition;
};

Barba.Dispatcher.on('transitionCompleted', function(currentStatus, oldStatus) {
    if (oldStatus) {
        $('.barba-animation-left').removeClass('leave');
        $('.barba-animation-left').addClass('enter');

        $('.barba-animation-right').removeClass('leave');
        $('.barba-animation-right').addClass('enter');

        setTimeout(function(){
            $('.barba-animation-left').removeClass('enter');
            $('.barba-animation-right').removeClass('enter');
        }, 500);
    }

    runWebsiteScripts();
});

Barba.Dispatcher.on('newPageReady', function(current, prev, container) {
    history.scrollRestoration = 'manual';
    trackPageGTM();
});


$(document).ready(function() {
    let barbaContainer = $('.barba-container').length;

    if (barbaContainer) {
        Barba.Pjax.cacheEnabled = false;
        Barba.Pjax.start();
        Barba.Pjax.getTransition();
    } else {
        runWebsiteScripts();
    }

    HD.utils.mobileMenuOpen();
});

// GOOGLE_MAP_API_KEY = AIzaSyDwJhLkc8ZS_ETZeU59qepyV4t85NX14h8
